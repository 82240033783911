import React from 'react';

import { useTranslation } from 'react-i18next';
import { Row, Col,Button, Table, Space } from 'antd';
import { IState as IStateContainer } from './prescripcionesActivasExternasContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { AlignType } from 'rc-table/lib/interface';
import { TratamientosActualesData } from './entities/tratamientosActualesData';

const NewPrescripcionesActivas = (props: {
    visible: boolean;
    onCancel: () => void;
    onSubmit: () => void;
    onAddTratamientoActual: () => void;
    unmarkToDeleteTratamientoActual?: (codeNacional?: number, codeDcpf?:number) => void;
    addToDeleteTratamientoActual?: (codeNacional?: number, codeDcpf?:number) => void;
} & IStateContainer) => {

    const { t } = useTranslation(['newPatientPrescription']);
    const tratamientosActionsRenderer = (value: any, record: TratamientosActualesData) => {
        return (
            (record.codeCodigoNacional ? !props.deleteTratamientosActuales!.includes(record.codeCodigoNacional): !props.deleteTratamientosActualesDcpf!.includes(record.codeDcpf!)) ? <Space size='small'>
                {(record.codeCodigoNacional ? props.newTratamientosActuales!.includes(record.codeCodigoNacional) : props.newTratamientosActualesDcpf!.includes(record.codeDcpf!)) ? <text className='newText'> {t("new")}</text> : <></>}
                <Button size='small' type='default' onClick={() => { props.addToDeleteTratamientoActual!(record.codeCodigoNacional,record.codeDcpf) }}>{t('delete')}</Button>
            </Space> :
                <Space size='small'>
                    <text className='checkedToDeleteText'>{t("checkedToDelete")}</text>
                    <Button size='small' type='default' onClick={() => { props.unmarkToDeleteTratamientoActual!(record.codeCodigoNacional,record.codeDcpf) }}>{t('anulate')}</Button>
                </Space>
        )
    }


    const tratamientoColumns = [
        { title: t('nameColumn'), width: "16rem",dataIndex: 'name', ellipsis: true, align: 'left' as AlignType, render: (value: any, record: TratamientosActualesData) => {return <div className='tableNameDiv'>{record.name}</div>}},
        { align: 'right' as AlignType, width: "10rem", render: tratamientosActionsRenderer}
    ]

    return (<>
        {props.visible && <div className='aditionalPatientInfoForm' style={{ padding: '1rem' }}>

            {
                <Row gutter={24}>
                    <Col span={24}>
                        <div className='addAlergiesDiv'>
                            {t('tratamientosActuales')}
                            <Button className='button__addtable' type={'primary'} onClick={() => props.onAddTratamientoActual()} icon={<FontAwesomeIcon icon={faPlus} style={{ fontSize: '0.8rem' }} />}></Button>
                        </div>
                    </Col>
                    <Col span={24}>
                        {(props.tratamientos && props.tratamientos.length != 0) ?
                            <div className='tableContainer'>
                                {<Table
                                    columns={tratamientoColumns}
                                    size='small'
                                    dataSource={props.tratamientos}
                                    rowClassName={() => "tableRow"}
                                    rowKey='codeCie10'
                                    pagination={false} />}
                            </div> :
                            <div className='emptyAlergiesTable'>
                                <span>{t('noTratamientosActuales')}</span>
                            </div>}

                    </Col>
                </Row>
            }
        </div>}
    </>
    );
};

export default NewPrescripcionesActivas;