import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Rest } from '../../../utils/utils';
import Medicine from '../entities/medicine';
import View from './SelectMedicine';
import _ from 'lodash';
import Dcpf from '../entities/dcpf';
import {IMedicamentoDescriptionVidal} from '../entities/IMedicamentoDescriptionVidal';
import { notification } from 'antd';
import ProblemaSuministroInfo from '../entities/problemaSuministroInfo';
import EnumPrescriptionType from '../../../enum/EnumPrescriptionType';

interface IProps {
    visible?: boolean;
    typePrescription?: number;
    onClose: (medicine?: Medicine, otherPrdouct?: any, dcpf?:Dcpf) => void;
    setMedicine: (medicine: any) => Medicine;
    idPrescriptionGroup?: number;
    modifyFlag?: boolean;
    flagPacienteMutualista?: boolean;
}

export interface IState {
    page: number;
    limit: number;
    dataCount: number;
    search?: string;
    medicineList?: any[];
    medicineSelected?: Medicine;
    vmpVisible?: boolean;
    vmpUrl?: string;
    vmpKey?: number;
    medicamentoDetailsVidalVisible: boolean;
    medicamentoDescriptionVidal?: IMedicamentoDescriptionVidal;
    psumSelectedInfo?: ProblemaSuministroInfo;
    typeOfSearch: number;
    favoritos: number[];
}

class SelectMedicineContainer extends React.Component<IProps & WithTranslation & RouteComponentProps, IState> {
    
    public state: IState = {
        page: 1,
        limit: 100,
        dataCount: 0,
        medicamentoDetailsVidalVisible: false,
        typeOfSearch: Number(this.props.typePrescription) === EnumPrescriptionType.MEDICAMENTO ?  0 : 1,
        favoritos: []

    }

    public render() {
        return (<>
            <View
                {...this.state}
                flagPacienteMutualista={this.props.flagPacienteMutualista}
                visible={this.props.visible}
                typePrescription={this.props.typePrescription}
                onCancel={() => this.props.onClose()}
                searchMedicine={this.searchMedicine}
                onChangePage={this.onChangePage}
                onSelect={this.onSelectMedicine}
                onSelectDcpf={this.onSelectDcpf}
                onSearchMedicine={this.doSearchMedicine}
                setMedicine={this.props.setMedicine}
                getPsumInfo={this.getPsumInfo}
                onChangeTypeSearch={this.changeTypeSearch}
                onAddFavorito={this.onAddFavorito}
                onDeleteFavorito={this.onDeleteFavorito}
                onSelectProduct={this.onSelectProduct}
                />
            </>
        )
    }
    
    private onAddFavorito = (id:number, type: number) => {
        
        if (type == 0){
            Rest<{type: string; codeDcpf: number}, any>().operation({
                type: 'InsertPrestadorProductoFav',
                codeDcpf: id
            }, true).then(response => {
               
            });
        } else{
        
            Rest<{type: string; codeCodigoNacional: number}, any>().operation({
                type: 'InsertPrestadorProductoFav',
                codeCodigoNacional: id
            }, true).then(response => {            
            });           
        }
        this.setState({favoritos: this.state.favoritos.concat(id)})
    }

    private onDeleteFavorito = (id:number, type: number ) => {
    
        if (type == 0){
            Rest<{type: string; codeDcpf: number}, any>().operation({
                type: 'DeletePrestadorProductoFav',
                codeDcpf: id
            }, true).then(response => {
               
            });          
        }else{
            Rest<{type: string; codeCodigoNacional: number}, any>().operation({
                type: 'DeletePrestadorProductoFav',
                codeCodigoNacional: id
            }, true).then(response => {
            });
        }
        let favs = this.state.favoritos;
            favs.splice(favs.indexOf(id),1);
            this.setState({favoritos: favs})

    }

    

    private searchMedicine = _.debounce((value?: string) => this.doSearchMedicine(value), 250);

    private doSearchMedicine = (value?: string) => {
        let searchValue = this.state.search;
        let page = this.state.page;
        let typeProducto = this.props.typePrescription;
        let favoritosBusqueda:number[] = [];

        if (value) {
            page = 1;
            searchValue = value;
        }

        if (!searchValue) {
            return;
        }

        if (Number(this.props.typePrescription) === EnumPrescriptionType.VACUNA_CATALOGADA) {
            typeProducto = EnumPrescriptionType.VACUNA;
        }
        if (Number(this.props.typePrescription) === EnumPrescriptionType.FORMULA_MAGISTRAL_CATALOGADA) {
            typeProducto = EnumPrescriptionType.FORMULA_MAGISTRAL;
        }
        if (Number(this.props.typePrescription) === EnumPrescriptionType.NUTRICION_CATALOGADA) {
            typeProducto =  EnumPrescriptionType.NUTRICION;
        }
        if (Number(this.props.typePrescription) === EnumPrescriptionType.PRODUCTO_SANITARIO_CATALOGADO) {
            typeProducto = EnumPrescriptionType.PRODUCTO_SANITARIO;
        } 

        const searchValues = searchValue?.includes(' ') ? searchValue.split(' ') : [searchValue];

        Rest<{type: string; filters: string[], typeProducto?: number, limit: number, offset: number}, any>().operation({
            type: this.state.typeOfSearch == 0 ? 'SelectProductoDcpf': 'SelectProductoVw',
            filters: searchValues,
            typeProducto: typeProducto,
            limit: this.state.limit,
            offset: (page - 1) * this.state.limit
        }, true).then(response => {
            response.data.forEach((element:any) =>{ if (element.flagFavorito == 1) favoritosBusqueda.push( this.state.typeOfSearch == 0 ? element.codeDcpf: element.codeCodigoNacional)} );
            this.setState({medicineList: response.data, favoritos: favoritosBusqueda, dataCount: response.dataCount, search: searchValue, page, medicineSelected: undefined});
        });
    }

    private onChangePage = (page: number) => {
        this.setState({ page }, () => this.searchMedicine());
    }

    private changeTypeSearch = (type: any) => {
        this.setState({typeOfSearch: type.target.value, medicineList:undefined}, () => this.searchMedicine());
    }

    private onSelectProduct = (record: any) => {
        this.props.onClose(undefined,record,undefined);
    }

    private onSelectMedicine = (idVademecum: number) => {
        const promises: Array<Promise<any>> = [];
        const req1 = Rest<{type: string; idVademecum: number; idPrescriptionGroup?: number; modifyFlag: boolean}, boolean>().operation({type: 'CheckPsychotropicNarcoticOnSelectMedicine', idVademecum, idPrescriptionGroup: this.props.idPrescriptionGroup, modifyFlag: this.props.modifyFlag!});
        const req2 = Rest<{type: string; idVademecum: number;}, any>().operation({type: 'SelectMedicineById', idVademecum});
        promises.push(req1);
        promises.push(req2);
        Promise.all(promises).then(response => {
            if(response[0]==true){
                this.props.onClose(this.props.setMedicine(response[1]));
                /*notification['warning']({
                    message: this.props.t('alreadyPsychotropicNarcoticError')
                });  
                return;*/
            }else{
                this.props.onClose(this.props.setMedicine(response[1]));
            }
        });
    }

    private onSelectDcpf = (codeDcpf: string) => {
        const promises: Array<Promise<any>> = [];
        const req1 = Rest<{type: string; codeDcpf: string; idPrescriptionGroup?: number; modifyFlag: boolean}, boolean>().operation({type: 'CheckPsychotropicNarcoticOnSelectMedicine', codeDcpf, idPrescriptionGroup: this.props.idPrescriptionGroup, modifyFlag: this.props.modifyFlag!});
        const req2 = Rest<{type: string; codeDcpf: string;}, any[]>().operation({type: 'SelectDCPFByCode', codeDcpf});
        promises.push(req1);
        promises.push(req2);
        Promise.all(promises).then(response => {
            /*if(response[0]==true){
                notification['warning']({
                    message: this.props.t('alreadyPsychotropicNarcoticError')
                });  
                return;
            }else{*/
                const responseList = response[1];
                const dcpf = responseList[0];
                if (responseList.length > 1) {
                    dcpf.valueUnidadContenido = undefined;
                }
                this.props.onClose(undefined,undefined, dcpf);
            /*}*/
        });
    }

    private getPsumInfo = (idVademecum: number) => {
        Rest<{type: string; idVademecum: number}, ProblemaSuministroInfo>().operation({
            type: 'GetPsumInfoByIdVademecum',
            idVademecum
        }, true).then(response => {
            this.setState({psumSelectedInfo: response});
        });
    }
}

export default withTranslation('medicineConfig')(withRouter(SelectMedicineContainer));