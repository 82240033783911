import React from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import { PrescriptionAlertsContext } from '../../context/PrescriptionAlertsContext';
import { Rest } from '../../utils/utils';
import alertsSummaryEditingPrescription from '../savePrescription/entities/alertsSummaryEditingPrescription';
import prescriptionLineUnsecurized from '../savePrescription/entities/prescriptionLineUnsecurized';
import {IAlertsDataArray, IAlertsDataItem} from './alertsData';
import PrescriptionAlertsContainer from './PrescriptionAlertsContainer';
import PrescriptionAlertsInformModal from './PrescriptionAlertsInformModal';
import PrescriptionAlertsPanel from './PrescriptionAlertsPanel';

interface IProps {
    visible?: boolean;
    idPrescriptionGroup?: number;
    calledFrom: string;
    pending?: boolean;
    idPatient?:number;
    codeDiagnostico?:string;
    editingPrescription?:alertsSummaryEditingPrescription;
    refresh?: number;
    dismiss?: boolean;
    save?: boolean;
    level: number;
    onCriticVidalAlert?: (critic?: boolean) => void;
}
export interface IState {
    prescriptionLinesList?: prescriptionLineUnsecurized[];
    alertsMaxLevel?: string;
    nAlertsMaxLevel?: number;
    nAlertsRest?: number;
    waitingAlerts?: boolean;
    prescriptionAlertsVisible?: boolean;
    alertsVidalInformeKey?: number;
    alertsVidalInformeVisible?: boolean;
    arrayOfAlerts?: IAlertsDataArray[];
    idPrescriptionGroup?: number;
    unsecurizedDrugsNames?: string[];
}

class PrescriptionAlertsPanelContainer extends React.Component<WithTranslation & RouteComponentProps & IProps, IState> {

    public state: IState = {
        prescriptionAlertsVisible: false,
        alertsVidalInformeVisible: false
    }
    static contextType = PrescriptionAlertsContext;
    
    public componentDidMount() {

        const alerts = this.context.getSummaryData();
        if (alerts) {
            this.setState({...alerts})
        } else {
            this.GetAlertsSummary();
        }
    }

    public componentWillReceiveProps(nextProps: Readonly<WithTranslation & RouteComponentProps & IProps>, nextContext: WithTranslation & RouteComponentProps & IProps): void {
        
        if (this.props.refresh == nextProps.refresh!-1) {
            this.GetAlertsSummary();
        }else if(this.props.refresh == nextProps.refresh!-2){
            this.setState({alertsMaxLevel:undefined, nAlertsMaxLevel: undefined, nAlertsRest: undefined,prescriptionLinesList: undefined}, () => this.props.onCriticVidalAlert ? this.props.onCriticVidalAlert(false): () => {});
        } else if(!this.props.dismiss && nextProps.dismiss) {
            this.context.deleteSummaryData(this.props.level);
        } else if(!this.props.save && nextProps.save) {
            this.context.saveSummaryData();
        }
    }
    
    public render() {
        return (
            <>
                <PrescriptionAlertsPanel
                    visible={this.props.visible}
                    pending={this.props.pending}
                    waitingAlerts={this.state.waitingAlerts}
                    alertsMaxLevel={this.state.alertsMaxLevel}
                    nAlertsMaxLevel={this.state.nAlertsMaxLevel}
                    nAlertsRest={this.state.nAlertsRest}
                    prescriptionLinesList={this.state.prescriptionLinesList}
                    onClickAlertsVidalInforme={this.onClickAlertsVidalInforme}
                    onClickShowAlertsModal={this.onClickShowAlertsModal}
                    />

                <PrescriptionAlertsContainer
                        visible={this.state.prescriptionAlertsVisible}
                        onCancel={this.closePrescriptionAlerts}
                        arrayOfAlerts={this.state.arrayOfAlerts || []}
                />

                <PrescriptionAlertsInformModal
                        key={this.state.alertsVidalInformeKey}
                        visible={this.state.alertsVidalInformeVisible}
                        onCancel={this.onCloseAlertsVidalInforme}
                />
            </>
        );
    }

    private closePrescriptionAlerts = () => {
        this.setState({prescriptionAlertsVisible: false});
    }

    private onClickShowAlertsModal = () => {
        this.GetAlertsFullResult(this.props.idPrescriptionGroup);
    }

    private GetAlertsFullResult = (idPrescripcionGrupo?: number) => {
        if(this.props.calledFrom=="newPrescription"){
            if(idPrescripcionGrupo!=null){
                Rest<{type: string, idPrescripcionGrupo: number, idPaciente?: any}, any> ().operation({
                    type: 'GetAllAlerts',
                    idPrescripcionGrupo,
                    idPaciente: this.props.idPatient
                }).then(response => {
                    const resultArrayOfAlerts: IAlertsDataArray[] = [];
                    Object.keys(response.allAlertsGrouped).forEach((key) => {
                        const alertId = Number(key);
                        const alertsData: IAlertsDataItem[] = response.allAlertsGrouped[key];
                        resultArrayOfAlerts.push({ alertId: alertId, alertsData });
                    });
    
                    let key = 0;
                    resultArrayOfAlerts.forEach( e => {
                            e.alertsData.forEach(a => {
                               a.severity = this.props.t('severity_short:' + a.severityId);
                               a.alertType = this.props.t(a.alertType);
                               a.key = (key++).toString();
                            });
    
                            e.alertsData.sort((a,b) => b.severityId - a.severityId);
    
                    });
    
                    this.setState({
                        prescriptionAlertsVisible: true,
                        arrayOfAlerts: resultArrayOfAlerts,
                    })
                });
            }
        }else{
            Rest<{type: string, idPrescripcionGrupo: number,idPaciente:any,codeCie10Diagnostico:any,prescripcionEnEdicion:alertsSummaryEditingPrescription}, any> ().operation({
                type: 'GetAllAlerts',
                idPrescripcionGrupo: idPrescripcionGrupo!,
                idPaciente: this.props.idPatient,
                codeCie10Diagnostico: this.props.codeDiagnostico,
                prescripcionEnEdicion: this.props.editingPrescription!
            }).then(response => {
                const resultArrayOfAlerts: IAlertsDataArray[] = [];
                Object.keys(response.allAlertsGrouped).forEach((key) => {
                    const alertId = Number(key);
                    const alertsData: IAlertsDataItem[] = response.allAlertsGrouped[key];
                    resultArrayOfAlerts.push({ alertId: alertId, alertsData });
                });

                let key = 0;
                resultArrayOfAlerts.forEach( e => {
                        e.alertsData.forEach(a => {
                            a.severity = this.props.t('severity_short:' + a.severityId);
                            a.alertType = this.props.t(a.alertType);
                            a.key = (key++).toString();
                        });
    
                        e.alertsData.sort((a,b) => b.severityId - a.severityId);
    
                });
    
                this.setState({
                    prescriptionAlertsVisible: true,
                    arrayOfAlerts: resultArrayOfAlerts,
                })
            });
        }
    }

    private GetAlertsSummary = async (editing?: boolean) => {
        if((this.props.pending==undefined || this.props.pending==true)){
            var editingPrescription = null;
            
            editingPrescription = this.props.editingPrescription;

            if(editingPrescription!=null || this.props.idPrescriptionGroup!=null){
                this.setState({waitingAlerts:true});
                Rest<{type: string, idPrescripcionGrupo: any,idPaciente:any,codeCie10Diagnostico:any,prescripcionEnEdicion:any}, any> ().operation({
                    type: 'GetAlertsSummary',
                    idPrescripcionGrupo: this.props.idPrescriptionGroup,
                    idPaciente: this.props.idPatient,
                    codeCie10Diagnostico: this.props.codeDiagnostico,
                    prescripcionEnEdicion: editingPrescription
                },true).then(response => {
                    if(response!=null){
                        this.context.saveTempSummaryData({
                            alertsMaxLevel:response.maxSeverityLevelCode, 
                            nAlertsMaxLevel: response.maxSeverityLevelAlertsCount, 
                            nAlertsRest: response.restAlertsCount,
                            prescriptionLinesList: response.prescriptionLinesUnsecurized
                        }, this.props.level)

                        if(response.maxSeverityLevelCode=="LEVEL_4"){
                            this.props.onCriticVidalAlert && this.props.onCriticVidalAlert!(true);
                        }else{
                            this.props.onCriticVidalAlert && this.props.onCriticVidalAlert!(false);
                        }
                        this.setState({waitingAlerts:false,alertsMaxLevel:response.maxSeverityLevelCode, nAlertsMaxLevel: response.maxSeverityLevelAlertsCount, nAlertsRest: response.restAlertsCount,prescriptionLinesList: response.prescriptionLinesUnsecurized})
                    }else{
                        this.setState({waitingAlerts:false,alertsMaxLevel:undefined, nAlertsMaxLevel: undefined, nAlertsRest: undefined,prescriptionLinesList: undefined});
                    }
                }); 

            }else{
                this.setState({waitingAlerts:false,alertsMaxLevel:undefined, nAlertsMaxLevel: undefined, nAlertsRest: undefined,prescriptionLinesList: undefined});
            }
        }
    }

    private onCloseAlertsVidalInforme = () => {
        this.setState({alertsVidalInformeVisible: false})
    }
    private onClickAlertsVidalInforme = () => {
        if(this.props.editingPrescription){
                Rest<{ type: string, idPrescripcionGrupo: number,idPaciente:any,codeCie10Diagnostico:any,prescripcionEnEdicion:alertsSummaryEditingPrescription}, any>()
                        .operation({type: 'GetAllAlertsHtml', 
                        idPrescripcionGrupo: this.props.idPrescriptionGroup!,
                        idPaciente: this.props.idPatient,
                        codeCie10Diagnostico: this.props.codeDiagnostico,
                        prescripcionEnEdicion: this.props.editingPrescription!})
                        .then(response => {
                            this.setState({alertsVidalInformeVisible: true,alertsVidalInformeKey: new Date().getTime()});
                });
        }
        else{
            Rest<{ type: string, idPrescripcionGrupo: number}, any>()
                    .operation({type: 'GetAllAlertsHtml', idPrescripcionGrupo: this.props.idPrescriptionGroup!})
                    .then(response => {
                        this.setState({alertsVidalInformeVisible: true,alertsVidalInformeKey: new Date().getTime()});
            });
        }
    }

}

export default withTranslation('prescription')(withRouter(PrescriptionAlertsPanelContainer));