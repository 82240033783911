
import React, { ReactNode } from 'react';

import { Layout } from 'antd'; 
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import Header from './HeaderEXTContainer';
import PrescriptionsListContainer from '../prescriptions/prescriptionsListContainer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import Logo from '../images/logo500.png';

import './styles/css/stylesEXT.scss';

import cx from 'classnames';
import { Rest } from '../utils/utils';
import PrestadorConfigContext, { IPrestadorConfigData } from '../context/PrestadorConfigContext';
import OtpAuthenticationFormContainer from '../otpAuthentication/OtpAuthenticationFormContainer';

interface IState {
    title: string;
    defaultTitle: string;
    namePrestador: string;
    component: ReactNode;
    idCompaniaCosalud: number;
    sessionClosed?: boolean;
    prestadorConfigData: IPrestadorConfigData;
    otpAuthenticationVisible?: boolean;
    otpAuthenticationFormKey?: number;
    reconfig?: boolean;
}

class AppEXT extends React.Component<WithTranslation & RouteComponentProps<{noHeader: string}>, IState> {

    public noHeader: boolean = this.props.location.search.includes('noHeader');

    private setPrestadorConfigData = (ctxData: IPrestadorConfigData) => {
        this.setState({prestadorConfigData: ctxData});
    };

    public componentDidMount = () => {

        document.body.classList.add('ext');

        const promises: Array<Promise<any>> = [];
        const req1 = Rest<{ type: string }, any>();
        const req2 = Rest<{ type: string }, IPrestadorConfigData>();
        promises.push(req1.operation({type: 'GetExternalAccessSessionInfo'}, false, true));
        promises.push(req2.operation({type: 'GetPrestadorConfig'})); // PrestadorConfig

        Promise.all(promises).then(response => {
            const defaultTitle = this.props.t('prescripcionesDe', {namePaciente: response[0].namePaciente});
            this.setState({ title: defaultTitle, defaultTitle, prestadorConfigData: {
                    vidalEnabled: response[1].vidalEnabled, vidalAppToken: response[1].vidalAppToken,
                    closeSessionAfterSign: response[1].closeSessionAfterSign, closeSessionExtAccess: this.onCloseSession}
                }, () => {
                    if(response[1].otpMANeedsConfig == true){
                        this.onOpenOtpAuthentication();
                    }
                });
            
        });
    }

    public componentWillUnmount = () => {
        document.body.classList.remove('ext');
    }

    public render() {

        return (!this.state ? null : this.state.sessionClosed ? <Layout className='ext-layout'>
                <div className='session-closed'>{this.props.t('sesionCerrada')}</div>
            </Layout> : 
            <PrestadorConfigContext.Provider
                    value={{data: this.state.prestadorConfigData, fn: this.setPrestadorConfigData}}>
                <Layout className='ext-layout'>
                    {!this.noHeader && <Layout.Header className='header-app'>
                        <Header onClickBlocked={this.onHeaderClickBlocked} 
                            idCompaniaCosalud={this.state.idCompaniaCosalud} 
                            onOpenOtpAuthentication={this.onOpenOtpAuthentication}
                            onCloseSession={this.onCloseSession}/>
                    </Layout.Header>}
                    <Layout.Content>
                        <div>
                            <div className={'ext-titulo' + (this.noHeader ? ' noHeader' : '')}>
                                {this.noHeader && <img src={Logo} className='logo--no-header'/>}
                                <span onClick={this.resetView} className={cx({active: !this.state.component})}>{this.state.defaultTitle}</span>
                                {this.state.component &&
                                <>
                                    <FontAwesomeIcon icon={faAngleRight} />
                                    <span className='active'>
                                        {this.state.title}
                                    </span>
                                    <FontAwesomeIcon className='close-icon' icon={faTimes} onClick={this.resetView} />
                                </>}
                            </div>
                        </div>
                        <div className='ext-content'>
                            {this.renderView()}
                        </div>
                    </Layout.Content>
                    <OtpAuthenticationFormContainer
                        key={this.state.otpAuthenticationFormKey}
                        visible={this.state.otpAuthenticationVisible}
                        isExternalAccess={true}
                        reconfigExt={this.state.reconfig}
                        onClose={this.onCloseOtpAuthentication}
                    />
                </Layout>
                
            </PrestadorConfigContext.Provider>
        );
    }

    private onOpenOtpAuthentication = (reconfig?: boolean) => {
        
        this.setState({otpAuthenticationVisible: true, otpAuthenticationFormKey: new Date().getTime(), reconfig: reconfig});
    }

    private onCloseOtpAuthentication = () => {
        this.setState({otpAuthenticationVisible:false});
    }

    private renderView = () => {

        if (this.state.component) {
            return this.state.component;
        } else {
            return <PrescriptionsListContainer addTab={this.changeView} closeTab={this.resetView} fixedPatient/>
        }
    }

    private changeView = (title: string, component: ReactNode) => {
        this.setState({title, component});
    }

    private resetView = () => {
        this.changeView(this.state.defaultTitle, undefined);
    }

    private onHeaderClickBlocked = () => {
        this.changeView(this.state.defaultTitle, <PrescriptionsListContainer addTab={this.changeView} closeTab={this.resetView} fixedPatient blocked/>)
    }

    private onCloseSession = () => {
        Rest<{type: string},{}>().operation({type: 'Logout'}).then(() => {
            this.setState({sessionClosed: true});
        });
    }

}

export default withTranslation(['gco'])(withRouter(AppEXT));