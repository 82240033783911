import React from 'react';

import { useTranslation } from 'react-i18next';
import { Result, Button } from 'antd';
import Modal from 'antd/lib/modal/Modal';

const SignMultipleSignModal  = (props: { visible?: boolean,onClose: () => void}) => {
        const {t} = useTranslation(['signPrescripction']);

        return (
        <Modal visible = {props.visible} maskClosable={false} footer = {null} closable={false} onCancel={() => props.onClose()} width={546}>
            <Result
                
                title = {t('multipleSignsTitle')}
                status="warning"
               
                extra = {
                    <span className='signMultipleSpan'>
                        <span style={{marginBottom: '2rem', alignSelf: 'center'}}> {t('multipleSignsSubtitle')}</span>
                        <Button type='default' onClick={() => props.onClose()}>
                            {t('buttons:continue')}
                        </Button>
                    </span>
                }
            >
            </Result>
        </Modal>
        )
    }

export default SignMultipleSignModal;