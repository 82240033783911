import React, { useState } from 'react';

import { Button, Input, Modal, Space, Table, Pagination, Popover, Tooltip, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { AlignType } from 'rc-table/lib/interface';
import { IState as IStateContainer } from './SelectMedicineContainer';
import Medicine from '../entities/medicine';
import Highlighter from 'react-highlight-words';
import EnumPrescriptionType from '../../../enum/EnumPrescriptionType';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faHourglass, faSearch, faStar, faTruck, faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import MedicineInfoPopOver from './MedicineInfoPopOver';
import MUFACE_ICONO from '../../../images/mufaceIcono.png';
import ProductPopOver from './ProductPopOver';

const SelectMedicine = (props: {
    flagPacienteMutualista?: boolean;
    visible?: boolean;
    typePrescription?: number;
    onCancel: () => void;
    searchMedicine: (value?: string) => void;
    onSearchMedicine: (value?: string) => void;
    onChangePage: (page: number) => void;
    onSelect: (idVademecum: number) => void;
    onSelectDcpf: (codeDcpf: string) => void;
    setMedicine: (medicine: any) => Medicine;
    getPsumInfo: (idVademecum: number) => void;
    onChangeTypeSearch: (type: any) => void;
    onAddFavorito: (id: number, type: number) => void;
    onDeleteFavorito: (id: number, type: number) => void;
    onSelectProduct: (record: any) => void;
} & IStateContainer) => {

    const { t } = useTranslation(['medicineConfig']);
    const { Search } = Input;
    const [searchValue, setSearchValue] = useState("");
    const [popoverVisible, setPopoverVisible] = useState(false);
    const [popoverVisibleId, setPopoverVisibleId] = useState(-1);

    const changeSearchValue = (value: string) => {
        setSearchValue(value);
        props.searchMedicine(value);
    }
    const content = (record: Medicine) => {
        return <div className='psumInfo'>
            <span className='psumInfo-periodoTitle'>{t('periodoPsum')}</span>
            <span className='psumInfo-periodo'>{!props.psumSelectedInfo?.dateFin ? t('desdePsum', { dateInicio: props.psumSelectedInfo?.dateInicio.toLocaleString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' }) }) : t('desdeHastaPsum', { dateInicio: props.psumSelectedInfo?.dateInicio.toLocaleString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' }), dateFin: props.psumSelectedInfo?.dateFin.toLocaleString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' }) })}</span>
            <span className='psumInfo-observacionesTitle'>{t('observacionesPsum')}</span>
            {props.psumSelectedInfo?.valueObservaciones && <span className='psumInfo-observaciones'>{props.psumSelectedInfo.valueObservaciones}</span>}
            <Button className='psumInfo-buttonSearchDcpf' size='small' type='primary' icon={<FontAwesomeIcon icon={faSearch} style={{ color: 'white', fontSize: '2rem' }} />} onClick={() => { changeSearchValue(record.nameDcpf!); setPopoverVisible(false) }}>{<div>{t('buttonSearchWithDcpf')}</div>}</Button>
        </div>
    }

    const nameRenderer = (value: any, record: any) => {
        if (props.search) {
            // Función para normalizar texto eliminando tildes y convirtiendo a minúsculas
            const normalizeText = (text: string) =>
                text
                    .normalize('NFD') // Descompone caracteres con tildes
                    .replace(/[\u0300-\u036f]/g, '') // Elimina los diacríticos
                    .toLowerCase(); // Convierte a minúsculas
    
            // Normaliza las palabras de búsqueda
            const searchValues = props.search.includes(' ')
                ? props.search.split(' ').map(normalizeText)
                : [normalizeText(props.search)];
    
            return props.search ? (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className="selectMedicineNameDiv" style={{ display: 'block', fontWeight: 'bold' }}>
                        <Highlighter
                            highlightStyle={{ backgroundColor: '#f8d19b', padding: 0 }}
                            searchWords={searchValues}
                            autoEscape
                            textToHighlight={
                                record.nameProducto ? record.nameProducto : record.nameDcpf
                            }
                            sanitize={(text) => normalizeText(text)} // Normaliza texto antes de comparar
                        />
                        <div className="selectmedicine__icon__container">
                            {props.flagPacienteMutualista && record.typeCatalogoMuface ? (
                                <Tooltip title={t('isMuface')}>
                                    <div className="circleFlag">
                                        <img src={MUFACE_ICONO} />
                                    </div>
                                </Tooltip>
                            ) : null}
                            {props.flagPacienteMutualista && record.typeCatalogoMuface ? (
                                record.flagVisado || record.flagVisado75 ? (
                                    <Tooltip title={t('yesVisado')}>
                                        <div className="circleFlag visado">V</div>
                                    </Tooltip>
                                ) : (
                                    <Tooltip title={t('noVisado')}>
                                        <div className="circleFlag">V</div>
                                    </Tooltip>
                                )
                            ) : null}
                            {props.flagPacienteMutualista && record.typeCatalogoMuface ? (
                                record.flagFinanciadoSns ? (
                                    <Tooltip title={t('yesFinanciado')}>
                                        <div className="circleFlag financiado">F</div>
                                    </Tooltip>
                                ) : (
                                    <Tooltip title={t('noFinanciado')}>
                                        <div className="circleFlag">F</div>
                                    </Tooltip>
                                )
                            ) : null}
                            <Tooltip
                                title={t(
                                    props.typeOfSearch === 0 &&
                                    (props.favoritos.includes(record.codeDcpf) ||
                                        props.favoritos.includes(record.codeCodigoNacional))
                                        ? 'isFavorito'
                                        : 'isNotFavorito'
                                )}
                            >
                                <div className="productoSearchIcon">
                                    <Button
                                        icon={<FontAwesomeIcon icon={faStar} />}
                                        className={
                                            props.typeOfSearch === 0 &&
                                            (props.favoritos.includes(record.codeDcpf) ||
                                                props.favoritos.includes(record.codeCodigoNacional))
                                                ? 'favorito'
                                                : ''
                                        }
                                        onClick={
                                            props.typeOfSearch === 0 &&
                                            (props.favoritos.includes(record.codeDcpf) ||
                                                props.favoritos.includes(record.codeCodigoNacional))
                                                ? () =>
                                                    props.onDeleteFavorito(
                                                        props.typeOfSearch === 0
                                                            ? record.codeDcpf
                                                            : record.codeCodigoNacional,
                                                        props.typeOfSearch
                                                    )
                                                : () =>
                                                    props.onAddFavorito(
                                                        props.typeOfSearch === 0
                                                            ? record.codeDcpf
                                                            : record.codeCodigoNacional,
                                                        props.typeOfSearch
                                                    )
                                        }
                                    ></Button>
                                </div>
                            </Tooltip>
                            <Tooltip title={t(record.flagAnterior ? 'isAnterior' : 'isNotAnterior')}>
                                <div className="productoSearchIcon">
                                    <FontAwesomeIcon
                                        className={record.flagAnterior ? 'anterior' : ''}
                                        icon={faHourglass}
                                    />
                                </div>
                            </Tooltip>
                            {props.typeOfSearch === 1 && (
                                <Popover
                                    overlayClassName={'psumInfoPopOver'}
                                    content={content(record)}
                                    trigger="click"
                                    visible={popoverVisible && popoverVisibleId === record.idVademecum}
                                    placement="bottom"
                                    style={{ width: '100px', padding: 0 }}
                                    onVisibleChange={(visible) => {
                                        if (record.flagPsum !== 1) {
                                            return;
                                        }
                                        setPopoverVisible(visible);
                                        if (visible) {
                                            setPopoverVisibleId(record.idVademecum);
                                        } else {
                                            setPopoverVisibleId(-1);
                                        }
                                    }}
                                >
                                    <Tooltip
                                        title={t(
                                            record.flagPsum === 1
                                                ? 'suministroError'
                                                : 'noSuministroError'
                                        )}
                                    >
                                        <span
                                            onClick={() =>
                                                props.getPsumInfo(record.idVademecum)
                                            }
                                            className={
                                                'productoSearchIcon psumInfo ' +
                                                (record.flagPsum === 1 ? 'activo' : '')
                                            }
                                        >
                                            <FontAwesomeIcon icon={faTruck} />
                                        </span>
                                    </Tooltip>
                                </Popover>
                            )}
                            {/* Aquí puedes añadir más Popovers o Tooltips */}
                            <Tooltip
                                title={t(
                                    record.flagEstupefaciente === 1 && record.flagPsicotropo === 1
                                        ? 'psicotropo/estupefac'
                                        : record.flagPsicotropo === 1 && record.flagEstupefaciente === 0
                                            ? 'medicPricotropico'
                                            : record.flagEstupefaciente === 1 && record.flagPsicotropo === 0
                                                ? 'medicEstupefaciente'
                                                : 'nopsicotropo/estupefac'
                                )}
                            >
                                <span className={
                                    'productoSearchIcon psico ' +
                                    (record.flagEstupefaciente === 1 || record.flagPsicotropo === 1
                                        ? 'activo'
                                        : '')
                                }
                                >
                                    <FontAwesomeIcon icon={faExclamationTriangle} />
                                </span>
                            </Tooltip>
                        </div>
                    </div>
    
                    {record.nameDcpf && record.nameProducto && (
                        <div style={{ display: 'flex', color: 'dimgray' }}>
                            DCPF:&nbsp;
                            <Highlighter
                                highlightStyle={{ backgroundColor: '#f8d19b', padding: 0 }}
                                searchWords={searchValues}
                                autoEscape
                                textToHighlight={record.nameDcpf}
                                sanitize={(text) => normalizeText(text)} // Normaliza texto antes de comparar
                            />
                        </div>
                    )}
                </div>
            ) : record.nameProducto
                ? record.nameProducto
                : record.nameDcpf;
        }
        return record.nameProducto ? record.nameProducto : record.nameDcpf;
    };
    

    const actionsRenderer = (value: any, record: any) => {
        return (
            <Space size='small'>

                {record.idVademecum && <Tooltip title={t('prescribeButton')}>
                    <Button size='small' type='default'
                        icon={<FontAwesomeIcon icon={faCheck} style={{ color: '#0091FF', fontSize: '0.8rem' }} />}
                        onClick={() => { props.onSelect(record.idVademecum) }}></Button>
                </Tooltip>}

                {!record.codeDcpf && !record.idVademecum && <Tooltip title={t('prescribeButton')}>
                    <Button size='small' type='default'
                        icon={<FontAwesomeIcon icon={faCheck} style={{ color: '#0091FF', fontSize: '0.8rem' }} />}
                        onClick={() => { props.onSelectProduct(record) }}></Button>
                </Tooltip>}

                {record.codeDcpf && <Tooltip title={t('prescribeDcpfButton')}>
                    <Button size='small' type='default'
                        icon={<FontAwesomeIcon icon={faCheck} style={{ color: '#0091FF', fontSize: '0.8rem' }} />}
                        onClick={() => props.onSelectDcpf(record.codeDcpf!)} style={{ color: '#0091FF' }}><span
                            className='spanSelectDCPFButton'>{t('selectDCPF')}</span></Button>
                </Tooltip>}

                {props.typePrescription != EnumPrescriptionType.MEDICAMENTO &&
                    <ProductPopOver
                        type={props.typePrescription}
                        id={props.typePrescription == EnumPrescriptionType.NUTRICION_CATALOGADA ? record.packageId : record.codeCodigoNacional}
                        visible={true}

                    />
                }
                {(record.idVademecum || record.codeDcpf) &&
                    <MedicineInfoPopOver
                        idVademecum={record.idVademecum}
                        codeNacional={record.codeCodigoNacional}
                        codeDcpf={record.codeDcpf}
                        visible={true}
                        flagDetallePorBaja={record.flagDetallePorBaja}
                        setMedicine={props.setMedicine}
                    />
                }
            </Space>
        );
    };

    const columns = [
        { title: t('medicineColumn'), dataIndex: 'nameProducto', align: 'left' as AlignType, render: nameRenderer },
        { align: 'right' as AlignType, width: 275, render: actionsRenderer },
    ];

    const [searchRef, setSearchRef] = useState<Input | null>(null);

    return (
        <Modal
            title={props.typePrescription === EnumPrescriptionType.MEDICAMENTO ? t('selectMedicineModalTitle') : t('selectProductCataloged')}
            style={{ top: 40 }}
            visible={props.visible}
            onCancel={() => props.onCancel()}
            footer={null}
            width={1250}
            destroyOnClose
        >
            <>
                {props.typePrescription == EnumPrescriptionType.MEDICAMENTO && <div className='radioGroupTypeSearch'>
                    <Radio.Group onChange={(type) => { searchRef?.focus(); props.onChangeTypeSearch(type); }} value={props.typeOfSearch}>
                        <Radio value={0}>{t('principioActivo')}</Radio>
                        <Radio value={1}>{t('nombreComercial')}</Radio>
                    </Radio.Group>
                </div>}

                <Search size='large' enterButton onSearch={changeSearchValue} ref={setSearchRef}
                    value={searchValue}
                    onChange={(e: any) => changeSearchValue(e.currentTarget.value)}
                    placeholder={t('searchPlaceholder')} autoFocus={true} />

                {props.medicineList &&
                    <div className='table-container' style={{ marginTop: '1rem' }}>
                        <Table
                            columns={columns}
                            size='small'
                            dataSource={props.medicineList}
                            rowKey={props.typePrescription == EnumPrescriptionType.MEDICAMENTO ? 'idVademecum' : (props.typePrescription == EnumPrescriptionType.NUTRICION ? 'packageId' : 'codeCodigoNacional')}
                            pagination={false} />

                        <Pagination
                            className='table-pagination'
                            size='small'
                            current={props.page}
                            pageSize={props.limit}
                            total={props.dataCount}
                            showSizeChanger={false}
                            hideOnSinglePage={true}
                            onChange={props.onChangePage} />
                    </div>}
            </>
        </Modal>

    );

};
export default SelectMedicine;
