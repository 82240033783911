import { FormInstance, Modal } from 'antd';
import React from 'react';
import View from './newPrescripcionesActivas';
import {withTranslation, WithTranslation} from 'react-i18next';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import FormErrorField from "../../utils/form/formErrorField";
import { Rest } from '../../utils/utils';
import SelectMedicineContainer from '../savePrescription/medicine/SelectMedicineContainer';
import Medicine from '../savePrescription/entities/medicine';
import Dcpf from '../savePrescription/entities/dcpf';
import EnumPrescriptionType from '../../enum/EnumPrescriptionType';
import { TratamientosActualesData, TratamientosActualesDataBackend } from './entities/tratamientosActualesData';

interface IProps {
    visible?: boolean;
    patientSelected: number;
    onClose: (save?:boolean) => void;
    setMedicine: (medicine: any) => Medicine;
}
export interface IState {
    loaded?: boolean;

    tratamientos?: TratamientosActualesData[];
    listOfTratamientoActualKey?: number;
    listOfTratamientoActualVisible?: boolean;

    addTratamientoActualExternoViewVisible?: boolean;
    addTratamientoActualExternoViewKey?: number;
    errorFields?:  FormErrorField[];
    formularioForm?: FormInstance;

    newTratamientosActuales?: number[];
    newTratamientosActualesDcpf?: number[];

    deleteTratamientosActuales?: number[];
    deleteTratamientosActualesDcpf?: number[];

}

class PrescripcionesActivasExternasContainer extends React.Component<WithTranslation & RouteComponentProps & IProps, IState> {

    public state: IState = {
        errorFields: [],
        tratamientos: [],
        newTratamientosActuales:[], 
        newTratamientosActualesDcpf:[],
        deleteTratamientosActuales: [],
        deleteTratamientosActualesDcpf: [],
        listOfTratamientoActualVisible: true
    };

    public componentDidMount() {
        if (this.props.visible) {
            this.loadData();
        }
    }
    
    public render() {
        return (
            <Modal title={""}
                   className= {"aditionalInfoModal"}
                   style={{top: 40}}
                   visible={this.props.visible}
                   onCancel={((!this.state.addTratamientoActualExternoViewVisible) ? () => this.props.onClose() : (() => this.onCloseAddTratamientoActual()))}
                   onOk={() => this.validateAndSave()}
                   bodyStyle={{paddingTop: '0.5rem', paddingBottom: '1.5rem'}}
                   width={700}
                   okText={this.props.t('buttons:save')} cancelText={this.props.t('buttons:cancel')}
                   destroyOnClose
                   okButtonProps={(this.state.addTratamientoActualExternoViewVisible) ? { style: { display: 'none' }}:{}}
            >
                {this.state.listOfTratamientoActualVisible && <View
                {...this.state}
                visible={this.state.listOfTratamientoActualVisible}
                key={this.state.listOfTratamientoActualKey}
                onCancel={() => this.props.onClose()}
                onAddTratamientoActual={this.onAddTratamientoActual} 
                addToDeleteTratamientoActual={this.addToDeleteTratamientoActual}
                unmarkToDeleteTratamientoActual={this.unmarkToDeleteTratamientoActual}
                onSubmit={this.validateAndSave}/>}

                {this.state.addTratamientoActualExternoViewVisible && <SelectMedicineContainer
                    key={this.state.addTratamientoActualExternoViewKey}
                    /*fromDiagnostico={false}*/
                    typePrescription={EnumPrescriptionType.MEDICAMENTO}
                    visible={this.state.addTratamientoActualExternoViewVisible}
                    onClose={this.onCloseAddTratamientoActual} 
                    setMedicine={this.props.setMedicine}                
                />}
            </Modal>
        );
    }

    private loadData = () => {
        Rest<{ type: string,idPatient: number}, TratamientosActualesDataBackend[]>().operation({
            type: 'SelectTratamientosActualesByIdPatient',
            idPatient: this.props.patientSelected!
        }).then((response) => {
            const responseFinal: TratamientosActualesData[] = response.map(item => ({
                name: item.name,
                codeCodigoNacional: item.codeCodigoNacional ? Number(item.codeCodigoNacional): undefined,
                codeDcpf: item.codeDcpf ? Number(item.codeDcpf) : undefined
              }));
            this.setState({tratamientos:responseFinal});
      });
    }

    private onAddTratamientoActual = () => {
        this.setState({addTratamientoActualExternoViewVisible: true,
            listOfTratamientoActualVisible: false});
           
    }

    private addToDeleteTratamientoActual = (codeNacional?: number, codeDcpf?: number) => {
        if(codeNacional!=null){
            this.setState({deleteTratamientosActuales: this.state.deleteTratamientosActuales?.concat(codeNacional)})
        }
        else{
            this.setState({deleteTratamientosActualesDcpf: this.state.deleteTratamientosActualesDcpf?.concat(codeDcpf!)})
        }
    }
        

    private unmarkToDeleteTratamientoActual = (codeNacional?: number,codeDcpf?: number) => {
        var cadenaAux: number[]= [];
        if(codeNacional!=null){
            cadenaAux= this.state.deleteTratamientosActuales!;
            cadenaAux.splice(this.state.deleteTratamientosActuales!.indexOf(codeNacional),1);
            this.setState({deleteTratamientosActuales: cadenaAux})
        }else{
            cadenaAux= this.state.deleteTratamientosActualesDcpf!;
            cadenaAux.splice(this.state.deleteTratamientosActualesDcpf!.indexOf(codeDcpf!),1);
            this.setState({deleteTratamientosActualesDcpf: cadenaAux})
        }
        
    }

    private onCloseAddTratamientoActual= (medicine?: Medicine, otherProduct?: any, dcpf?: Dcpf) => {
        var newTratamientoActual : TratamientosActualesData = {};
        if(medicine){
            newTratamientoActual.codeDcpf = undefined
            newTratamientoActual.codeCodigoNacional= parseInt(medicine.codeCodigoNacional);
            newTratamientoActual.name = medicine.nameProducto;
        }else if(dcpf){
            newTratamientoActual.codeCodigoNacional = undefined
            newTratamientoActual.codeDcpf= parseInt(dcpf!.codeDcpf);
            newTratamientoActual.name = dcpf!.nameDcpf;
        }
        
        if(newTratamientoActual.name!=null && !this.state.tratamientos!.includes(newTratamientoActual) && !this.state.newTratamientosActuales!.includes(newTratamientoActual.codeCodigoNacional!) && !this.state.newTratamientosActualesDcpf!.includes(newTratamientoActual.codeDcpf!)){
            this.setState({listOfTratamientoActualVisible: true,addTratamientoActualExternoViewVisible: false,tratamientos:this.state.tratamientos!.concat(newTratamientoActual), newTratamientosActuales: newTratamientoActual.codeCodigoNacional ? (this.state.newTratamientosActuales!.concat(newTratamientoActual.codeCodigoNacional)) :this.state.newTratamientosActuales, newTratamientosActualesDcpf: newTratamientoActual.codeDcpf ? (this.state.newTratamientosActualesDcpf!.concat(newTratamientoActual.codeDcpf)) : this.state.newTratamientosActualesDcpf});
        }else{
            this.setState({listOfTratamientoActualVisible: true,addTratamientoActualExternoViewVisible: false,addTratamientoActualExternoViewKey:undefined});
        }
    }

    private validateAndSave = () => {
       
       this.setState({ errorFields: [] }, () => {
                Rest<{ type: string,idPaciente: number, newTratamientosActuales?: number[], newTratamientosActualesDcpf?: number[], deleteTratamientosActuales?: number[], deleteTratamientosActualesDcpf?: number[]}, number>().operation({
                    type: 'UpdateTratamientosActuales',
                    idPaciente: this.props.patientSelected!,
                    newTratamientosActuales: this.state.newTratamientosActuales,
                    newTratamientosActualesDcpf: this.state.newTratamientosActualesDcpf,
                    deleteTratamientosActuales: this.state.deleteTratamientosActuales,
                    deleteTratamientosActualesDcpf: this.state.deleteTratamientosActualesDcpf
                }).then(() => {
                    this.props.onClose(true);
              });
                
            });
                
        }


}

export default withTranslation('newPatientPrescription')(withRouter(PrescripcionesActivasExternasContainer));